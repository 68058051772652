<template>
<div class="etf-port">

  <span class="text-h5 text--primary d-flex pa-2 font-weight-bold"> 글로벌 자산배분 전략
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-icon class="pl-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
      </template>
      <BacktestHelp
        :he02 = "true"
      />
    </v-tooltip>
  </span>


  <div>
  <v-card flat>
    <v-tabs>
      <v-tab @click="get_port_list('my')">
        <v-icon left>
          mdi-account
        </v-icon>
        나의 전략
      </v-tab>
      <v-tab @click="get_port_list('expert')">
        <v-icon left>
          mdi-account-multiple-outline
        </v-icon>
        전문가 전략
      </v-tab>
    </v-tabs>
  </v-card>

  <v-card
    flat
  >    
    <v-card-subtitle align="end">      
      <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-information </v-icon>
            </template>
            <span>투자전략</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-chart-arc </v-icon>
            </template>
            <span>추천 리밸런싱 배분율</span>
        </v-tooltip> 
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon class="pr-4" color="primary" dark v-on="on"> mdi-plus </v-icon>
            </template>
            <span>나의 포트폴리오 추가</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon class="pr-16" color="primary" dark v-on="on"> mdi-delete </v-icon>
            </template>
            <span>포트삭제</span>
        </v-tooltip>            
    </v-card-subtitle>
  </v-card>

  <v-data-table
      :headers="portHeaders"
      :items="portList"
      item-key="port_id"
      class="elevation-1"
      :hide-default-footer="true"
      :loading="loadTable"
      :items-per-page="100"
      loading-text="로딩중... 잠시만 기다려 주세요." 
  > 
  <template v-slot:top>  
      <v-dialog 
          v-model="dialogDelete"
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
      >
          <v-card>
              <v-card-title class="text-h6">{{title}} 포트를 삭제하시겠습니까?</v-card-title>
              <v-card-actions class="pa-6">
              <v-spacer></v-spacer>
              <v-btn depressed outlined @click="closeDelete">취소</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm">확인</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>      

      <!-- 편입 ETF 배분율 보기 및 수정 -->
      <v-dialog 
          v-model="dialogEtf" 
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
      >
          <v-card>
              <v-card-title class="text-h5" >{{ title }}</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="assetsHeaders"
                  :items="assets"
                  :hide-default-footer=true
                  class="elevation-1"  
                  :items-per-page="100"
                  no-data-text="자산이 없습니다."     
                > 
                </v-data-table>                  
              </v-card-text>
              <v-card-actions class="pa-6">
                  <v-spacer></v-spacer>                    
                  <v-btn depressed outlined @click="dialogEtf = false">닫기</v-btn>
                  <!-- <v-btn color="primary" @click="update_etfs()">저장</v-btn> -->
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- 편입 ETF 보기 -->
      <v-dialog 
          v-model="dialogEtfList" 
          width="auto" 
          :fullscreen="$vuetify.breakpoint.xsOnly"
      >
          <v-card>
              <v-card-title class="text-h5" >{{ title }}</v-card-title>
              <v-card-actions>
                  <v-data-table
                      :headers="etfHeaders"
                      :items="etfList"
                      item-key="name"
                      class="elevation-1"
                      :hide-default-footer="true"
                      :loading="loadEtfTable"
                      loading-text="로딩중... 잠시만 기다려 주세요." 
                  ></v-data-table> 
              </v-card-actions>
              <v-card-actions class="pa-6">
                  <v-spacer></v-spacer>
                  <v-btn depressed outlined @click="close">닫기</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- 전략보기 -->
      <v-dialog 
          v-model="dialogTest"
          max-width="800"
          :fullscreen="$vuetify.breakpoint.xsOnly"
      >
          <v-card>
              <v-card-title class="text-h5">{{title}} 투자전략</v-card-title>
              <v-card-text>
                <v-card-subtitle class="d-flex justify-start ma-1" v-if="who !== 'expert'">
                본인이 수립한 전략을 입력 및 수정할수 있습니다.
                </v-card-subtitle>
                <v-container>
                  <v-row>
                    <v-col>
                      <!-- 전략설명문 -->
                      <v-textarea
                        outlined
                        auto-grow
                        v-model="description"
                        label="매수/매도 전략"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="ma_period"
                        label="이동평균 산정 구간"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="mt_period"
                        label="수익율 산정 구간"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="hold_to_assets"
                        label="공격자산 보유 종목수량"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              
              </v-card-text>
              <v-card-actions class="pa-6">
                  <v-spacer></v-spacer><v-spacer></v-spacer>
                  <v-btn depressed outlined @click="dialogTest = false">닫기</v-btn>
                  <template v-if="showDescriptionDialog">
                    <v-btn color="primary" @click="saveDescription()">저장</v-btn>
                  </template>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- 포트 추가 -->
      <v-dialog 
        v-model="dialogPort"
        max-width="700"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-progress-linear
            :active="progressLoading"
            :indeterminate="progressLoading"
            absolute
            bottom
          ></v-progress-linear>

          <v-card-title>
            <span class="text-h5">나의 포트폴리오에 추가</span>
          </v-card-title>
          <v-card-text>
            <span class="d-flex justify-start">
              투자금은 해당 자산배분 전략의 자산별 배분율에 따라 자동 배부됩니다. (전일기준)
            </span>
            <br>
            <v-data-table
              :headers="assetsHeaders"
              :items="portAssets"
              :hide-default-footer=true
              class="elevation-1"  
              :items-per-page="100"              
              :loading="loadEtfTable"
              loading-text="로딩중... 잠시만 기다려 주세요." 
              no-data-text="해당 자산배분 전략에 따라 배분할 자산이 없습니다. 현금을 보유하세요. (전일기준)"     
            > 
            </v-data-table>    
            <br> 
            <v-text-field
              v-if="showComment"
              v-model="portTitle"
              label="포트폴리오 제목"
              hint="제목을 수정하세요."
              persistent-hint
            />
            <br>            
            <v-row v-if="showComment">
              <v-col cols="12" sm="6" md="4" lg="4"> 
                <v-text-field
                  v-model="portAmount"
                  label="투자금"
                  hint="투자금을 입력하세요."
                  persistent-hint
                  prefix="$"
                />
              </v-col>   
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="portFee"
                  label="수수료(%)"
                  hint="증권사 거래수수료를 입력하세요."
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>                
              <v-col cols="12" sm="6" md="4" lg="4"> 
                <v-text-field
                  v-model="portBroker"
                  label="증권사"
                  hint="거래 증권사를 입력하세요."
                  persistent-hint
                />
              </v-col>                                                   
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer><v-spacer></v-spacer>
            <v-btn depressed outlined @click="dialogPort=false">닫기</v-btn>
            <v-btn v-if="showComment" color="primary" @click="put_ports()">추가</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </template>
  <template v-slot:item.comment="{ item }">
      <v-icon small class="mr-4" @click="showHelp(item.port_id)"> mdi-help </v-icon>
  </template>
  <template v-slot:item.actions="{ item }">      
      <v-icon small class="mr-4" @click="showDescription(item)"> mdi-information </v-icon>
      <v-icon small class="mr-4" @click="showEtfs(item)"> mdi-chart-arc </v-icon>
      <v-icon small class="mr-4" @click="getAssets(item)"> mdi-plus </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
  </template>
  </v-data-table>
  
</div>

<!-- 알림 메세지 -->
<v-snackbar
v-model="snackbar"
:vertical="vertical"
:color="snackColor"          
>
{{ snackText }}

<template v-slot:action="{ attrs }">
    <v-btn
    text
    v-bind="attrs"
    @click="snackbar = false"
    >
    Close
    </v-btn>
</template>
</v-snackbar>

</div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import BacktestHelp from '../components/BacktestHelp.vue';  
  
  export default {
    name: 'EtfPort',   
    components: { BacktestHelp },
    data () {
      return {
        helpDialogShow: false,
        dialogEtf: false,
        dialogEtfList: false,
        dialogDelete: false,
        dialogTest: false,
        loadTable: false,
        loadEtfTable: false,
        dialogPort: false,
        progressLoading: false,
        showDescriptionDialog: false,
        showComment: false,

        helpTitle: null,
        description: null,
        ma_period: 10,
        mt_period: 12,
        hold_to_assets: 6,

        portTitle: null,
        portAmount: 10000,
        portFee: 0.25,
        portBroker: null,
        portHeaders: null,

        portList: [],
        etfList: [],
        assets: [],
        portAssets: [],
        amount_selected: 10000,
        term_selected: [],
        sumRatio: 100,

        title: null,
        editedIndex: -1,
        editedItem: {
          port_id: 0,
          title: '',
          today: 0.0,
          mtd: 0.0,
          ytd: 0.0,
        },

        defaultItem: {
          port_id: 0,
          title: '',
          today: 0.0,
          mtd: 0.0,
          ytd: 0.0,
        },
        myHeaders : [
          { text: '제목', value: 'title' },          
          { text: 'TYPE', value: 'type', align: 'start' },
          { text: '월누적수익율(MTD)', value: 'mtd', align: 'end' },
          { text: '년누적수익율(YTD)', value: 'ytd', align: 'end' },          
          { text: '누적수익율(CAGR)', value: 'cagr', align: 'end' },
          { text: 'SHARP', value: 'sharp', align: 'end' },
          { text: 'MDD', value: 'mdd', align: 'end' },           
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
        expertHeaders : [
          { text: '제목', value: 'title' },          
          { text: 'TYPE', value: 'type', align: 'start' },
          { text: '월누적수익율(MTD)', value: 'mtd', align: 'end' },
          { text: '년누적수익율(YTD)', value: 'ytd', align: 'end' },          
          { text: '누적수익율(CAGR)', value: 'cagr', align: 'end' },
          { text: 'SHARP', value: 'sharp', align: 'end' },
          { text: 'MDD', value: 'mdd', align: 'end' },           
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
        etfHeaders : [
          { text: '국가', value: 'country' },
          { text: 'ETF명', value: 'etf' },
          { text: '배분율(%)', value: 'ratio', align: 'center' },
          { text: '오늘수익율(%)', value: 'today', align: 'end' },
          { text: '월수익율(MTD)', value: 'mtd', align: 'end' },
          { text: '년수익율(YTD)', value: 'ytd', align: 'end' },
        ],
        assetsHeaders: [
          { text: '심볼', value: 'symbol', align: 'start', sortable: false },    
          { text: 'ETF명', value: 'etf', align: 'start', sortable: false },                  
          { text: '배분율(%)', value: 'ratio', align: 'center', sortable: false },
          { text: '자산분류', value: 'category', align: 'center', sortable: false },
        ],

        etfTableShow: false,
        portID: null,
        tradeTerms: ['월','분기','반기','년'],        
        test_text: {},
        who: null,
        portClass: null,

        he00: false,
        hee4: false,

        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,
    
      }
    },
    
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      who (val) {
        if (this.who == 'expert') {
          if (this.user == 'koas') {
            this.showDescriptionDialog = true;  
          } else {
            this.showDescriptionDialog = false;
          }
        } else {
          this.showDescriptionDialog = true;  
        }
      },
      portAmount (val) {
        const result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.portAmount = result;
      },
    },
    
    created() {
      if (this.isLogin) this.get_port_list('my');
      else this.$router.push("/");
    },

    computed: {
        ...mapState(['debug', 'isLogin', 'user']) 
    },
    
    methods: {
        ...mapMutations(['setEtfs', 'setEtfTest', 'setRoute']),

        get_port_list(whois) {
          this.loadTable = true;
          var vm = this;
          
          if (whois == 'my') {              
            this.who = this.user;
            this.portHeaders = this.myHeaders;
          } else if (whois == 'expert') {              
            this.who = 'expert';
            this.portHeaders = this.expertHeaders;
          }
          
          const req_data = {user: this.who};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/etf/port/list/', req_data, {headers})
              .then(function(res) {
                  vm.portList = res.data;
                  if (vm.portList.length == 0) {
                    vm.snackbar = true
                    vm.snackColor = 'orange darken-2'
                    vm.snackText = '자산찾기 메뉴에서 먼저 포트폴리오를 구성하세요.'
                  }
                  vm.loadTable = false;
              })
              .catch(function (err) { 
                vm.loadTable = false;
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;
              });
        },

        // depreceted (2022/11/11)
        get_etfs(item) {
            this.dialogEtfList = true;
            this.loadEtfTable = true;
            this.title = item['title'];
            this.portID = item['port_id'];
            var vm = this;
            
            const req_data = {user: this.who};
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            axios.post('/api/etf/port/etf/list/'+this.portID+'/', req_data, {headers})
                .then(function(res) {
                    vm.etfList = res.data;
                    vm.loadEtfTable = false;
                })
                .catch(function (err) {
                  vm.loadEtfTable = false;
                  vm.snackbar = true;
                  vm.snackColor = 'error';
                  vm.snackText = err;
                });
        },

        getAssets(item) {
          if (item.etfs.length === 0) {
            this.snackbar = true;
            this.snackColor = 'warning';
            this.snackText = '복수 전략으로 구성된 자산은 포트폴리오에 자동추가할 수 없습니다. 개별전략 단위로 추가바랍니다.';
            return
          }
          this.dialogPort = true;       
          this.loadEtfTable = true;
          this.portTitle = item['title'];
          this.portID = item['port_id'];
          this.portClass = item['type'];

          if (this.portClass === 'dynamic') {           
            var params = { 'who': this.who, 'user': this.user, 'quant_id': this.portID, 'assets': item.etfs };
            var vm = this;
              
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            axios.post('/api/quant/port/assets/global/', params, {headers})
              .then(function(res) {
                vm.loadEtfTable = false;
                vm.showComment = res.data.length === 0 ? false : true;
                vm.portAssets = res.data; 
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;
              });              
          } else {
            this.portAssets = item.etfs; 
            this.loadEtfTable = false;
            this.showComment = true;
          }
        },        

        put_ports() {
          this.progressLoading = true;
          var savePort = { 'who': this.who, 'user': this.user, 'title': this.portTitle, 'quant_id': this.portID, 'port_codes': this.portAssets, 
          'port_amount': this.portAmount, 'port_fee': this.portFee, 'port_broker': this.portBroker };
          var vm = this;
            
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/quant/port/save/global/', savePort, {headers})
            .then(function(res) {
              vm.dialogPort = false;  
              vm.$router.push('/global/port');
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;
            });
        },
        
        showDescription(item) {
          this.dialogTest = true;
          this.title = item['title'];
          this.portID = item['port_id'];
          var vm = this;
          
          const req_data = {user: this.who};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/etf/port/etf/description/'+this.portID+'/', req_data, {headers})
            .then(function(res) {
                vm.description = res.data['description']+'\n';
                vm.ma_period = res.data['ma_period'];
                vm.mt_period = res.data['mt_period'];
                vm.hold_to_assets = res.data['hold_to_assets'];
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;
            });
        },

        saveDescription() {
          var vm = this;
          
          const req_data = {user: this.who, 'description': this.description, 
            'ma_period': this.ma_period, 'mt_period': this.mt_period, 'hold_to_assets': this.hold_to_assets};
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
          axios.post('/api/etf/port/etf/description/save/'+this.portID+'/', req_data, {headers})
            .then(function(res) {
                vm.snackbar = true;
                vm.snackColor = 'success';
                vm.snackText = '저장하였습니다.';
                vm.dialogTest = false;
            })
            .catch(function (err) {
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;
            });
        },

        backtest_start() {
            this.test_text['amount'] = this.amount_selected;
            this.test_text['term'] = this.term_selected;
            this.setEtfTest(this.test_text);
            this.setRoute({'route': this.who});
            this.$router.push('/etf_backtest_result');
            this.dialogTest = false;
        },

        deleteItem (item) {
            this.title = item['title'];
            this.editedIndex = this.portList.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.portID = item['port_id'];
            this.dialogDelete = true;            
        },

        deleteItemConfirm () {
            var vm = this;            
            const req_data = {user: this.who}
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
            axios.post('/api/etf/port/delete/'+vm.portID+'/', req_data, {headers})
                .then(function(res) {
                  vm.portList.splice(vm.editedIndex, 1)
                  vm.closeDelete()
                })
                .catch(function (err) {
                  vm.snackbar = true;
                  vm.snackColor = 'success';
                  vm.snackText = '삭제되었습니다.';
                });            
        },

        showEtfs(item) {   
          var vm = this; 
          this.title = item.title;
          this.portID = item.port_id;
          this.assets = item.etfs;            
          this.dialogEtf = true;
        },

        close () {
            this.dialogEtf = false
            this.dialogEtfList = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        showHelp(no) {
          var helpNo = 'hee'+no;
          this.helpTitle = '도움말'; 
          helpNo == 'hee00' ? this.he00 = true : this.he00 = false; 
          helpNo == 'hee4' ? this.hee4 = true : this.hee4 = false;
          helpNo == 'hee5' ? this.hee5 = true : this.hee5 = false;
          helpNo == 'hee6' ? this.hee6 = true : this.hee6 = false;
          helpNo == 'hee7' ? this.hee7 = true : this.hee7 = false;
          helpNo == 'hee10' ? this.hee10 = true : this.hee10 = false;
          this.helpDialogShow = true;
        },

    }
  }
</script>


<style>
.etf-port{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
